import { useRef } from 'react';

import { DEVICE_TYPES, LAYOUTS, VERTICAL_TYPE } from '../../../constants';
import { usePolyCardCart } from './use-polycard-cart';
import { useTrackFirstSwipe } from '../../../hooks/use-track-first-swipe';
import { usePolyCardBookmark } from './use-polycard-bookmark';
import { usePolyCardLayout } from './use-polycard-layout';
import { usePolyCardLoadImage } from './use-polycard-interaction';

export const useItemToPolyCard = ({ item, vertical, layout, isShops, deviceType, listRef, index }) => {
  const isCPG = vertical === VERTICAL_TYPE.CPG;
  const isGrid = layout === LAYOUTS.TYPE_GRID;
  const isMobile = deviceType === DEVICE_TYPES.MOBILE;
  const isTablet = deviceType === DEVICE_TYPES.TABLET;
  const layoutIndex = isGrid || isMobile ? index : index + 1;

  const { swipe_gallery_tracks } = item?.itemPicturesTracks ?? {};

  const cardRef = useRef(null);

  const { hasFooter, hasVariations, isVisible, height } = usePolyCardLayout({
    cardRef,
    isMobile,
    isTablet,
    layout,
    listRef,
  });

  const { itemState, setItemState, handleMouseEnter, loadImagesCarousel } = usePolyCardLoadImage({
    hasVariations,
    isMobile,
    isTablet,
    isVisible,
    item,
    layout,
  });

  const { pageXUrl, setPageXUrl, addToCartObj, already_has_suggestions } = usePolyCardCart({
    isShops,
    itemState,
    layout,
    isCPG,
  });

  const { handleBookmark } = usePolyCardBookmark({ itemState, setItemState });

  const { handleGallerySwipe } = useTrackFirstSwipe(swipe_gallery_tracks);

  return {
    addToCartObj,
    already_has_suggestions,
    cardRef,
    handleBookmark,
    handleGallerySwipe,
    handleMouseEnter,
    hasFooter,
    hasVariations,
    height,
    isGrid,
    itemState,
    layoutIndex,
    loadImagesCarousel,
    pageXUrl,
    setPageXUrl,
  };
};
