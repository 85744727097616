import findPolycardComponent from '../../../utils/find-polycard-components';

export const getPolycardMetaData = (polycard) => {
  const metadata = polycard?.metadata || {};
  const { pictures = {}, tracks = {} } = metadata;

  return {
    ...metadata,
    pictures: { ...pictures, variation_id: pictures?.variation_id || '' },
    tracks: { ...tracks, cart_tracks: tracks?.cart_tracks || undefined },
  };
};

export const getPolyCardCartData = (item) => {
  const { category_id, group_by, id, inventory_id, is_pad, pictures, product_id, should_show_suggestions, tracks } =
    getPolycardMetaData(item);

  const cartComponent = findPolycardComponent(item, 'add_to_cart') || {};
  const {
    add_to_cart: {
      cart_config: { available_quantity = 0, min_add_quantity = 0, already_has_suggestions } = {},
      cart_shipping: { cart_free_shipping = 0 } = {},
      cart_status: { quantity = 0, text = '' } = {},
    } = {},
  } = cartComponent;

  const polycard = {
    availableQuantity: available_quantity,
    minimumQuantity: min_add_quantity,
    itemId: id,
    category: category_id,
    cart_request_initial_delay: null,
    cart_request_delay: 500,
    should_call_suggestions: should_show_suggestions,
    variation_id: pictures.variation_id,
    productId: product_id,
    inventoryId: inventory_id,
    weight_data: undefined,
    tracks: tracks.cart_tracks,
    groupBy: group_by,
    threshold: cart_free_shipping,
    isAd: is_pad,
    label_count: { text },
    quantity,
    isPolycard: true,
    already_has_suggestions,
  };

  return polycard;
};

export const getPolyItem = (itemState) => {
  const metadata = itemState?.metadata
    ? {
        ...itemState.metadata,
      }
    : itemState?.metadata;

  const pictures = itemState?.pictures
    ? {
        ...itemState?.pictures,
        pictures: itemState?.pictures?.pictures
          ? Array.from(itemState?.pictures?.pictures)
          : itemState?.pictures?.pictures,
      }
    : itemState?.pictures;

  return {
    ...itemState,
    metadata,
    pictures,
  };
};
