import { useState } from 'react';

import APIServiceBookMark from '../../../../services/bookmarks';
import redirectTo from '../../../utils/redirect-to';
import { useUser } from '../../../components/context/user';

export const usePolyCardBookmark = ({ itemState, setItemState }) => {
  const [isFetching, setIsFetching] = useState(false);
  const { loggedIn, loginUrl } = useUser();

  const handleBookmark = async (e, itemId) => {
    e.preventDefault();

    if (isFetching) {
      return;
    }

    if (!loggedIn) {
      redirectTo(loginUrl);

      return;
    }

    setIsFetching(true);

    try {
      const updatedBookmarkState = !itemState.bookmark.bookmarked;

      setItemState({
        ...itemState,
        bookmark: {
          bookmarked: updatedBookmarkState,
        },
      });

      await APIServiceBookMark.toggleBookmarkService(itemId, updatedBookmarkState);
    } catch (ex) {
      if (ex.response && ex.response.status === 403) {
        redirectTo(loginUrl);

        return;
      }

      setItemState({
        ...itemState,
        bookmark: {
          bookmarked: itemState.bookmark.bookmarked,
        },
      });
    } finally {
      setIsFetching(false);
    }
  };

  return {
    handleBookmark,
  };
};
