import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';

import PageXModal from '@mshops-components-library/modal-add-to-cart';
import { Card } from '@polycard/card';

import { usePolyCardClases } from '../hooks/use-polycard-classes';
import { ResultPropTypes } from '../../layout/constants/result.prop-types';
import { withComponentHandler } from '../../../hocs/with-component-handler';

const namespace = 'ui-search-result';

/**
 * @typedef {Object} PolyCardProps
 * @property {function} addToCartObj
 * @property {boolean} already_has_suggestions
 * @property {function} cardRef
 * @property {string} deviceType
 * @property {function} handleBookmark
 * @property {function} handleGallerySwipe
 * @property {function} handleMouseEnter
 * @property {boolean} hasFooter
 * @property {boolean} hasVariations
 * @property {boolean} isGrid
 * @property {boolean} isShops
 * @property {Object} item
 * @property {number} layoutIndex
 * @property {function} loadImagesCarousel
 * @property {function} pageXUrl
 * @property {function} setPageXUrl
 * @property {string} vertical
 */

/**
 * PolyCard component.
 *
 * @param {PolyCardProps} props
 * @returns {JSX.Element}
 */
export const PolyCardComponent = ({
  addToCartObj,
  already_has_suggestions,
  cardRef,
  deviceType,
  handleBookmark,
  handleGallerySwipe,
  handleMouseEnter,
  hasFooter,
  hasVariations,
  isGrid,
  isShops,
  item,
  layoutIndex,
  loadImagesCarousel,
  pageXUrl,
  setPageXUrl,
  vertical,
}) => {
  const { className, polyCardClass } = usePolyCardClases({
    isGrid,
    namespace,
    item,
    hasVariations,
    hasFooter,
    deviceType,
    vertical,
    isShops,
  });

  return (
    <div className={className} ref={cardRef}>
      <Card
        key={item?.metadata?.id}
        polycard={item}
        addToCartActions={addToCartObj}
        cardNumber={layoutIndex}
        bookmarkAction={handleBookmark}
        pickerAction={loadImagesCarousel}
        onMouseEnter={isGrid && handleMouseEnter}
        className={polyCardClass}
        suggestions={already_has_suggestions}
        onAfterImageChange={handleGallerySwipe}
      />
      {isShops && (
        <PageXModal
          id="mshops-page-x-modal"
          src={pageXUrl}
          onCloseModal={() => setPageXUrl(null)}
          deviceType={deviceType}
          show={!!pageXUrl}
        />
      )}
    </div>
  );
};

PolyCardComponent.propTypes = {
  addToCartObj: func,
  already_has_suggestions: bool,
  cardRef: func,
  deviceType: string,
  handleBookmark: func,
  handleGallerySwipe: func,
  handleMouseEnter: func,
  hasFooter: bool,
  hasVariations: bool,
  isGrid: bool,
  isShops: bool,
  item: shape(ResultPropTypes).isRequired,
  layoutIndex: number.isRequired,
  loadImagesCarousel: func,
  pageXUrl: func,
  setPageXUrl: func,
  vertical: string,
};

export const PolyCard = withComponentHandler(PolyCardComponent, {
  componentName: 'PolyCard',
});
