import useCartHandlers from '../../../components/card/add-to-cart/hooks/useCartHandlers';
import { getPolyCardCartData } from '../utils';

export const usePolyCardCart = ({ itemState, isCPG, layout, isShops }) => {
  const polycard = getPolyCardCartData(itemState);

  const { handleAddToCartPut, handleAddToCartDelete, pageXUrl, setPageXUrl } = useCartHandlers({
    already_has_suggestions: polycard.already_has_suggestions,
    availableQuantity: polycard.availableQuantity,
    cart_request_delay: 500,
    cart_request_initial_delay: null,
    category: polycard.category,
    groupBy: polycard.groupBy,
    inventoryId: polycard.inventoryId,
    isAd: polycard.isAd,
    isCpg: isCPG,
    isPolycard: true,
    isShops,
    itemId: polycard.itemId,
    label_count: polycard.label_count,
    minimumQuantity: polycard.minimumQuantity,
    productId: polycard.productId,
    quantity: polycard.quantity,
    should_call_suggestions: polycard.should_call_suggestions,
    threshold: polycard.threshold,
    tracks: polycard.tracks,
    variation_id: polycard.variation_id,
    viewMode: layout,
    weight_data: undefined, // pendiente por enviar para pesables
  });

  const addToCartObj = {
    handleAddToCartPut: (e, url) => handleAddToCartPut(e, url),
    handleAddToCartDelete: (e, url) => handleAddToCartDelete(e, url),
  };

  return { pageXUrl, setPageXUrl, addToCartObj, already_has_suggestions: polycard.already_has_suggestions };
};
