import { useEffect, useLayoutEffect, useState } from 'react';

import { LAYOUTS } from '../../../constants';

export const usePolyCardLayout = ({ cardRef, isMobile, isTablet, layout, listRef }) => {
  const [hasFooter, setHasFooter] = useState(false);
  const [hasVariations, setHasVariations] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (!cardRef?.current || layout !== LAYOUTS.TYPE_GRID || !listRef?.current) {
      return;
    }

    const { current: cardElement } = cardRef;
    const contentWrapper = cardElement.querySelector('.poly-card');
    const cardHeight = contentWrapper.getBoundingClientRect().height;

    listRef?.current?.style?.setProperty('height', `${cardHeight}px`);

    setHeight(cardHeight);
  }, [cardRef, listRef, layout]);

  useEffect(() => {
    const footer = cardRef?.current?.getElementsByClassName('poly-card__footer');
    const variations = cardRef?.current?.getElementsByClassName('poly-component__variations');

    setHasFooter(!!footer?.length);
    setHasVariations(!!variations?.length);
  }, [cardRef]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if ((isMobile && layout === LAYOUTS.TYPE_GALLERY) || (isTablet && layout === LAYOUTS.TYPE_GRID)) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
      };

      const observer = new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      }, options);

      const currentRef = cardRef.current;

      if (currentRef) {
        observer.observe(currentRef);
      }

      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }
  }, [cardRef, isMobile, isTablet, layout]);

  return {
    hasFooter,
    hasVariations,
    isVisible,
    setIsVisible,
    height,
  };
};
