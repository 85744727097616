import React, { useState } from 'react';
import { trackEvent } from '../lib/melidata';

export const useTrackFirstSwipe = (swipe_gallery_tracks) => {
  const [gallerySwiped, setGallerySwiped] = useState(false);

  const handleGallerySwipe = (slideIndex) => {
    if (slideIndex !== 1) return;

    if (!gallerySwiped && swipe_gallery_tracks) {
      setGallerySwiped(true);
      trackEvent({
        ...swipe_gallery_tracks.melidata_track,
        clean: true,
      });
    }
  };

  return { handleGallerySwipe };
};
