import classnames from 'classnames';

import { DEVICE_TYPES, VERTICAL_TYPE } from '../../../constants';

export const usePolyCardClases = ({
  isGrid,
  namespace,
  item,
  hasVariations,
  hasFooter,
  deviceType,
  vertical,
  isShops,
}) => {
  const isCPG = vertical === VERTICAL_TYPE.CPG;
  const isMobile = deviceType === DEVICE_TYPES.MOBILE;
  const isTablet = deviceType === DEVICE_TYPES.TABLET;

  const isResizable = !isMobile && isGrid;
  const hiddenPicture = hasVariations && item.pictures.pictures.length > 1;

  const className = classnames({
    [`${namespace}`]: isMobile && !isCPG,
    [`${namespace}__wrapper`]: !isMobile,
    [`${namespace}__wrapper--hidden_picture`]: hiddenPicture,
    [`${namespace}__wrapper--large`]:
      (hasFooter && isResizable && !isTablet) || (hasVariations && isResizable && !isTablet),
    [`${namespace}__wrapper--large-tablet`]: isTablet,
    '': isCPG,
  });

  const polyCardClass = classnames({
    'mshops-search-polycard': isShops,
  });

  return {
    className,
    polyCardClass,
  };
};
