import { useCallback, useEffect, useState } from 'react';

import APIServiceImage from '../../../../services/picturesCarousel';
import { getPolyItem } from '../utils';

export const usePolyCardLoadImage = ({ item, isMobile, isTablet, layout, hasVariations, isVisible }) => {
  const [itemState, setItemState] = useState(item?.polycard);
  const [loadedItems, setLoadedItems] = useState(new Set());
  const [isFetching, setIsFetching] = useState(false);
  const [mouseEntered, setMouseEntered] = useState(false);

  useEffect(() => {
    if (item?.polycard) {
      setItemState(item.polycard);
    }
  }, [item]);

  const loadImagesCarousel = useCallback(
    async (variation_id = null, isMouseEvent = false) => {
      const polyItem = getPolyItem(itemState);

      if (
        (isMobile && !hasVariations && loadedItems.has(polyItem.metadata.id)) ||
        (isTablet && loadedItems.has(polyItem.metadata.id)) ||
        (hasVariations && isMobile) ||
        isFetching ||
        layout === 'stack'
      ) {
        return;
      }

      setIsFetching(true);

      if (isMouseEvent) {
        polyItem.metadata.isLoading = true;
        setItemState(polyItem);
      }

      try {
        const response = await APIServiceImage.getCarouselPictures(
          polyItem.metadata.id || polyItem.metadata.product_id,
          variation_id || polyItem.pictures.variation_id || '',
          true,
        );

        const { pictures, tracks } = response;

        if (pictures[0]?.pictures?.length >= 1) {
          const polyPictures = polyItem.pictures.pictures;
          const pictureList = pictures[0].pictures;

          if (pictureList[0].id !== polyPictures[0].id && !hasVariations) {
            pictureList.unshift(polyPictures[0]);
          }

          polyItem.pictures = { ...polyItem.pictures, ...pictures[0] };
        }

        polyItem.itemPicturesTracks = tracks;
      } catch (err) {
        setItemState(polyItem);
      } finally {
        polyItem.metadata.isLoading = false;
        setIsFetching(false);
        setItemState(polyItem);
        setLoadedItems(new Set(loadedItems).add(polyItem.metadata.id));
      }
    },
    [itemState, layout, loadedItems, isFetching, hasVariations, isMobile, isTablet],
  );

  useEffect(() => {
    if (isVisible) {
      loadImagesCarousel();
    }
  }, [isVisible, loadImagesCarousel]);

  const handleMouseEnter = () => {
    if (!mouseEntered) {
      loadImagesCarousel(itemState?.pictures?.variation_id, true);
      setMouseEntered(true);
    }
  };

  return {
    handleMouseEnter,
    itemState,
    loadImagesCarousel,
    setIsFetching,
    setItemState,
  };
};
