import React from 'react';
import { bool, shape, string } from 'prop-types';

import { withComponentHandler } from '../../../hocs/with-component-handler';
import { ResultPropTypes } from '../../layout/constants/result.prop-types';
import { useItemToPolyCard } from '../hooks/use-item-to-polycard';
import { PolyCard } from './card';

/**
 * @typedef {Object} ItemToPolyCardProps
 * @property {string} deviceType - Tipo de dispositivo.
 * @property {string} index - Índice del item.
 * @property {boolean} isShops - Indica si es una tienda.
 * @property {Object} item - Objeto del item.
 * @property {string} layout - Diseño.
 * @property {string} vertical - Tipo vertical.
 */

/**
 * Componente que convierte un item en una tarjeta PolyCard.
 *
 * @param {ItemToPolyCardProps} props - Propiedades del componente.
 * @returns {JSX.Element} Componente PolyCard.
 */
export const ItemToPolyCardComponent = ({ item, index, isShops, layout, deviceType, vertical, listRef }) => {
  const {
    addToCartObj,
    already_has_suggestions,
    cardRef,
    handleBookmark,
    handleGallerySwipe,
    handleMouseEnter,
    hasFooter,
    hasVariations,
    isGrid,
    itemState,
    layoutIndex,
    loadImagesCarousel,
    pageXUrl,
    setPageXUrl,
  } = useItemToPolyCard({ deviceType, index, isShops, item, layout, listRef, vertical });

  return (
    <PolyCard
      item={itemState}
      layoutIndex={layoutIndex}
      cardRef={cardRef}
      addToCartObj={addToCartObj}
      handleBookmark={handleBookmark}
      pageXUrl={pageXUrl}
      setPageXUrl={setPageXUrl}
      handleMouseEnter={handleMouseEnter}
      handleGallerySwipe={handleGallerySwipe}
      isGrid={isGrid}
      isShops={isShops}
      deviceType={deviceType}
      already_has_suggestions={already_has_suggestions}
      loadImagesCarousel={loadImagesCarousel}
      hasVariations={hasVariations}
      hasFooter={hasFooter}
    />
  );
};

ItemToPolyCardComponent.propTypes = {
  deviceType: string,
  index: string,
  isShops: bool,
  item: shape(ResultPropTypes).isRequired,
  layout: string,
  listRef: string,
  vertical: string,
};

export const ItemToPolyCard = withComponentHandler(ItemToPolyCardComponent, {
  componentName: 'ItemToPolyCard',
});
